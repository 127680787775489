import styles from './Contact.module.scss';
import { useEffect } from 'react';
import { handlePageHighLight } from '../../contexts/functions'
import triangles from '../../assets/images/svg/cyanTriangle.svg'
import trianglesOnLeft from '../../assets/images/svg/trianglesOnLeft.svg'
import trianglesOnRight from '../../assets/images/svg/trianglesOnRight.svg'
import trianglesMobile from '../../assets/images/svg/trianglesMobile.svg'
import downArrow from '../../assets/images/svg/downArrow.svg'
import paper from '../../assets/images/svg/paper.svg';
import Button from '../../components/Button';

const Contact: React.FC = () => {
    useEffect(() => {handlePageHighLight('contact');});
    return (
        <main>
            <section className={styles.pageMainTitleContainer}>
                <figure>
                    <img src={triangles} alt="Header triangles" />
                </figure>
                <h1>Entre em contato</h1>

                <img src={trianglesOnLeft} alt="Left triangles" className={styles.pageMainTitleTrianglesOnLeft} />
                <img src={trianglesOnRight} alt="Right triangles" className={styles.pageMainTitleTrianglesOnRight} />
                <img src={trianglesMobile} alt="Mobile triangles" className={styles.pageMainTitleTrianglesMobile} />

                <img src={downArrow} alt="downArrow" className={styles.pageMainTitleDownArrowElement} />
            </section>

            <section className={styles.mainFormContainer}>
                <form onSubmit={(e) => {e.preventDefault();}}>
                    <label className={styles.formInputItem}>
                        <p>E-mail</p>
                        <input placeholder='Insira seu e-mail' type='email' />
                    </label>

                    <label className={styles.formInputItem}>
                        <p>Nome</p>
                        <input placeholder='Insira seu nome' type='text' />
                    </label>

                    <label className={styles.formInputItem}>
                        <p>Mensagem</p>
                        <textarea placeholder='Digite aqui...'></textarea>
                    </label>

                    <section className={styles.formSubmit}>
                        <label className={styles.formCheckbox}>
                            <input type="checkbox" name="politicsCheckbox" />
                            <div className={styles.formCheckboxCheckbox}>
                                <div className={styles.formCheckboxCheckmark}></div>
                            </div>
                            <span>Declaro que li e aceito as <b><u>políticas de privacidade</u></b></span>
                        </label>
                        <Button text="Enviar" src={paper} hasIcon={true} />
                    </section>
                </form>
            </section>
        </main>
    );
}

export default Contact;