class EmailFilters {
    private _name?: string
    private _email?: string

    constructor(name?: string, email?: string) {
        this._name = name;
        this._email = email;
    }

    get name(): string | undefined  {
        return this._name;
    }

    set name(value: string | undefined) {
        this._name = value;
    }

    get email(): string | undefined {
        return this._email;
    }

    set email(value: string | undefined) {
        this._email = value;
    }
}

export default EmailFilters
