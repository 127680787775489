const navOptions = ['home', 'about', 'contact'];


export const handlePageHighLight = (page: string) => {
    navOptions.forEach((option) => {
      document.getElementById(option)?.style.setProperty('color', '#000000');
      document.getElementById(option+'Mobile')?.style.setProperty('background', 'transparent');
      document.getElementById(option+'Mobile')?.style.setProperty('font-weight', '400');
      document.getElementById(option+'Mobile')?.style.setProperty('color', '#000000');
    });

    const target = document.getElementById(page);
    const targetMobile = document.getElementById(page+'Mobile');
    if (target && targetMobile) {
      target.style.setProperty('color', '#0071BB');
      targetMobile.style.setProperty('color', '#ffffff');
      targetMobile.style.setProperty('background', '#0071BB');
      targetMobile.style.setProperty('font-weight', '600');
    }
}
