import React, { useEffect } from 'react';
import { handlePageHighLight } from '../../contexts/functions';

import Button from '../../components/Button'
import styles from './Home.module.scss';
import logo from "../../assets/images/svg/logo.svg";
import logoSubtext from '../../assets/images/svg/logoSubtext.svg';
import cyanTriangles from '../../assets/images/svg/cyanTriangle.svg';
import megaphone from "../../assets/images/png/Megaphone.png";
import triangles from '../../assets/images/svg/triangles.svg';
import leftLightning from '../../assets/images/svg/lightningBolt01.svg';
import middleLightning from '../../assets/images/svg/lightningBolt02.svg';
import rightLightning from '../../assets/images/svg/lightningBolt03.svg';
import exclamationMark from '../../assets/images/svg/exclamationMark.svg';
import star from '../../assets/images/svg/star.svg';
import blackArrow from '../../assets/images/svg/blackArrow.svg';
import formCross from '../../assets/images/svg/cross.svg';
import paper from '../../assets/images/svg/paper.svg';
import cellPhone from '../../assets/images/png/Cellphone.png';

import GetEmailServices from "../../dicontainer/dicontainer";
import SupporterInfo from "../../../../core/domain/models/SupporterInfo";
import emailsList from '../../assets/data/emails';

const Home: React.FC = () => {

    const services = GetEmailServices()
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [subjectTitle, setSubjectTitle] = React.useState('Apoio à Modernização Democrática!');
    const [subject, setSubject] = React.useState('Prezado (a) Deputado (a),\n\n Sei que você é muito ocupado (a) com diversas demandas, mas gostaria de pedir o seu apoio aos PL’s 7005/2013 e 3770/2023 que, se aprovados, permitirão o uso de assinatura eletrônica em apoio a Iniciativa Popular. Isso permitirá maior participação do povo na construção de políticas públicas e legislações de interesse do povo e efetividade ao instrumento constitucional da Iniciativa Popular. \n\n Atualmente, para iniciativa popular, só se pode coletar apoios por meio de assinaturas físicas, o que impõe muitos limites e dificuldades em se promover a Iniciativa Popular em papel num país de dimensões continentais. \n\n Por isto, peço, encarecidamente, o seu apoio a estes projetos para que sejam votados e aprovados em plenário nesta sessão legislativa.\n\n Agradeço pelo seu incansável trabalho.\n\nAbraço!');
    const [sentNumber, setSentNumber] = React.useState(0);
    const [acceptPrivacyPolicies, setAcceptPrivacyPolicies] = React.useState(false);
    const [wereEmailsSent, setWereEmailsSent] = React.useState<boolean>();
    const [isDataValid, setIsDataValid] = React.useState<boolean>();

    useEffect(() => {
        handlePageHighLight('home')
        const emails = emailsList();
        emails.forEach((email) => {
        const input = document.getElementById('depList') as HTMLInputElement
        input.value += email + '; '
    });
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const number = await services.getSentNumber()
                setSentNumber(number)
            } catch (error) {
                console.error(error)
            }
        };
        fetchData()
    }, [services])

    async function onSubmit() {
        setIsDataValid(undefined)
        setWereEmailsSent(undefined)
        try {
            let fullname = `${name} ${lastName}`
            let supporterInfo = new SupporterInfo(fullname, email)
            if (supporterInfo.isValid()) {
                await services.send(supporterInfo)
                setWereEmailsSent(true)
                setIsDataValid(undefined)
                console.log("Email sent successfully!")
            } else {
                setIsDataValid(false)
                console.log("Informed data is invalid!")
            }
        } catch (error) {
            setWereEmailsSent(false)
            setIsDataValid(undefined)
            console.error("Error sending email:", error)
        }
    }

    return (
        <main style={{position: 'relative'}}> 
        {/* Component Start */}

        {/* Introduction Start */}
        <section className={styles.introductionContainer}>   
            <div className={styles.introductionTextContainer}>
                <aside className={styles.introductionText}> 
                    <h1>É Hora de <br /> <b>Modernização</b> </h1>
                    <span>PL 7005/2023 e 3770/2023 sobre a Iniciativa Popular</span>
                    <Button src={""} text="Quero Apoiar" hasIcon={false} onClick={() => {
                        window.location.href = "#form-section"
                    }} />
                </aside>
            </div>
            <figure className={styles.introductionElementContainer}>
                <img src={megaphone} alt="Megaphone Element" className={styles.introductionElementContainerMegaphone} />
                <img src={triangles} alt="Triangle Background Element" className={styles.introductionElementContainerTriangle} />
                <img src={cyanTriangles} alt="Triangles Element" className={styles.introductionElementContainerCyanTriangles} />
            </figure>
        </section>
        {/* Introduction End */}

        {/* About Start */}
        <article className={styles.aboutContainer}>
            <section className={styles.aboutContainerArticle}>
                <figure className={styles.aboutContainerArticleLogoContainer}>
                    <img src={logo} alt="Sua voz, Nossa lei Logo" className={styles.aboutContainerArticleLogo} />
                    <img src={logoSubtext} alt="Logo Complementar" className={styles.aboutContainerArticleLogoSubtext} />

                    <img src={leftLightning} alt="Left Lightning" className={styles.aboutContainerArticleLeftLightning} />
                    <img src={middleLightning} alt="Middle Lightning" className={styles.aboutContainerArticleMiddleLightning} />
                </figure>
                <aside className={styles.aboutContainerArticleContent}>
                    <h1>Oi! Me chamo Izael, e assim como você, sou um cidadão preocupado. 
                        Não podemos deixar que isso aconteça!</h1>
                    <p>Com toda a tecnologia a nosso alcance nossos direitos estão ficando 
                        para trás graças a uma velha burocracia que atrapalha e impede o 
                        fácil acesso aos nossos direitos.</p>
                    <p>A iniciativa popular é uma ferramenta poderosa da democracia que 
                        através do apoio de milhares de brasileiros tem o poder de apoiar 
                        Projetos de Lei que contribuem para a evolução dos nossos direitos 
                        e garante que possamos demonstrar apoio a grandes projetos.</p>
                    <p>Agora imagine poder expressar seu apoio a projetos de lei significativos 
                        sem sair de casa, com apenas alguns cliques! Imaginou? Vamos tornar 
                        possível.</p>

                    <img src={rightLightning} alt="Right Lightning" className={styles.aboutContainerArticleRightLightning} />
                </aside>
            </section>

            <section className={styles.aboutContainerWarning}>
                <div className={styles.aboutContainerWarningTitleContainer}>
                    <div className={styles.aboutContainerWarningTitleIconContainer}>
                        <img src={exclamationMark} alt="Exclamation Mark Icon" className={styles.aboutContainerWarningTitleIcon} />
                    </div>
                    <h1 className={styles.aboutContainerWarningTitle}>Essa é a nossa proposta!</h1>
                </div>
                
                <p className={styles.aboutContainerWarningParagraph}>Nossa proposta é justamente esta, <b>facilitar e tornar 
                    mais acessível a participação ativa da sociedade no processo legislativo, possibilitando que sua voz seja 
                    ouvida de maneira rápida e eficiente.</b> </p>
                <p className={styles.aboutContainerWarningParagraph}>Ao preencher nosso formulário com seu e-mail e nome, e 
                    clicar em "Enviar", ele será recebido diretamente nos gabinetes dos deputados federais, solicitando apoio 
                    aos projetos de lei que reconhecem a validade da assinatura digital para a Iniciativa Popular.</p>
                <p className={styles.aboutContainerWarningParagraph}>Ao enviar o e-mail, você está apoiando não apenas a 
                    modernização do processo democrático, mas também facilitando a realização de projetos de lei que impactarão 
                    positivamente nossa sociedade. Seu envolvimento mostra aos deputados federais que a população está unida 
                    e comprometida com as mudanças necessárias para construirmos um Brasil mais justo.</p>
                <p className={styles.aboutContainerWarningParagraph}>Abaixo, seguem alguns exemplos de projetos de lei de 
                    interesse social que poderiam ser propostos por iniciativa popular:</p>
            </section>
        </article>
        {/* About End */}

        {/* Law Projects Start */}
        <section className={styles.lawProjectsContainer}>
            <div className={styles.lawProjectsItemContainer}>
                <figure className={styles.lawProjectsItem}>
                    <img src={star} alt="Star" className={styles.lawProjectsItemStar} />
                    <h1>Lei de Acesso à Educação de Qualidade</h1>
                    <p>
                        Buscar garantir o acesso universal à educação de qualidade, estabelecendo diretrizes para a valorização 
                        dos professores, a melhoria da infraestrutura escolar e a promoção de políticas de inclusão.
                    </p>
                </figure>

                <figure className={styles.lawProjectsItem}>
                    <img src={star} alt="Star" className={styles.lawProjectsItemStar} />
                    <h1>Reforma do Sistema de Saúde</h1>
                    <p>
                        Propor medidas para aprimorar o sistema de saúde, garantindo o acesso universal a serviços de qualidade, 
                        investindo na infraestrutura hospitalar e promovendo a valorização dos profissionais de saúde.
                    </p>
                </figure>

                <figure className={styles.lawProjectsItem}>
                    <img src={star} alt="Star" className={styles.lawProjectsItemStar} />
                    <h1>Proteção dos Direitos dos Animais</h1>
                    <p>
                        Propor leis que fortaleçam a proteção dos direitos dos animais, estabelecendo normas rigorosas contra a 
                        crueldade, incentivando a adoção responsável e regulamentando práticas como testes em animais.
                    </p>
                </figure>

                <figure className={styles.lawProjectsItem}>
                    <img src={star} alt="Star" className={styles.lawProjectsItemStar} />
                    <h1>Incentivo à Agricultura Familiar</h1>
                    <p>
                        Apresentar um projeto que promova políticas de incentivo à agricultura familiar, visando fortalecer a 
                        produção local, garantir a segurança alimentar e promover práticas agrícolas sustentáveis.
                    </p>
                </figure>
            </div>

            <div className={styles.lawProjectsItemMobileContainer}>
                <label className={styles.lawProjectsItemMobileItem}>
                    <h1>Lei de Acesso à Educação de Qualidade</h1>
                    <input type="checkbox" />
                    <img src={blackArrow} alt="Drop Arrow" className={styles.dropdownButton} />

                    <p className={styles.lawProjectsItemMobileDropdown}>
                        Buscar garantir o acesso universal à educação de qualidade, estabelecendo diretrizes para a valorização 
                        dos professores, a melhoria da infraestrutura escolar e a promoção de políticas de inclusão.
                    </p>
                </label>

                <label className={styles.lawProjectsItemMobileItem}>
                    <h1>Reforma do Sistema de Saúde</h1>
                    <input type="checkbox" />
                    <img src={blackArrow} alt="Drop Arrow" className={styles.dropdownButton} />

                    <p className={styles.lawProjectsItemMobileDropdown}>
                        Propor medidas para aprimorar o sistema de saúde, garantindo o acesso universal a serviços de qualidade, 
                        investindo na infraestrutura hospitalar e promovendo a valorização dos profissionais de saúde.
                    </p>
                </label>

                <label className={styles.lawProjectsItemMobileItem}>
                    <h1>Proteção dos Direitos dos Animais</h1>
                    <input type="checkbox" />
                    <img src={blackArrow} alt="Drop Arrow" className={styles.dropdownButton} />
                    <p className={styles.lawProjectsItemMobileDropdown}>
                        Propor leis que fortaleçam a proteção dos direitos dos animais, estabelecendo normas rigorosas contra a 
                        crueldade, incentivando a adoção responsável e regulamentando práticas como testes em animais.
                    </p>
                </label>
                
                <label className={styles.lawProjectsItemMobileItem}>
                    <h1>Incentivo à Agricultura Familiar</h1>
                    <input type="checkbox" />
                    <img src={blackArrow} alt="Drop Arrow" className={styles.dropdownButton} />

                    <p className={styles.lawProjectsItemMobileDropdown}>
                        Apresentar um projeto que promova políticas de incentivo à agricultura familiar, visando fortalecer a 
                        produção local, garantir a segurança alimentar e promover práticas agrícolas sustentáveis.
                    </p>
                </label>
            </div>
        </section>
        {/* Law Projects End */}

        {/* form Start */}
        <section id="form-section" className={styles.formContainer}>
            <aside className={styles.formContainerTextContainer}>
                <img src={formCross} alt="Form Cross" />
                <h1>Participe agora! <br className={styles.mobileBreakline} /> Seu apoio FAZ <br className={styles.mobileBreakline} /> TODA DIFERENÇA!</h1>
            </aside>

            <form onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
            }} className={styles.mainForm}>
                <header>Preencha o formulário</header>
                <div className={styles.greyBackground}>
                    <p>Quem você vai pressionar: ({emailsList().length} alvos)</p>
                    <textarea id='depList' placeholder='example@email.com' disabled />
                </div>
                <div className={styles.formPersonalInfo}>
                    <p className={styles.formRequire}>E-mail</p>
                    <input type="text" name="Email" placeholder='Insira seu e-mail' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                </div>
                <div className={styles.formPersonalInfo}>
                    <p className={styles.formRequire}>Nome</p>
                    <input type="text" name="Name" placeholder='Insira seu nome' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
                </div>
                <div className={styles.formPersonalInfo}>
                    <p>Sobrenome</p>
                    <input type="text" name="Last Name" placeholder='Insira seu sobrenome' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
                </div>
                <div className={styles.greyBackground}>
                    <p>Assunto</p>
                    <input type="text" name="" value={subjectTitle} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubjectTitle(e.target.value)} />
                </div>
                <div className={styles.greyBackground}>
                    <p>Assunto</p>
                    <textarea className={styles.formSubject} value={subject} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>  setSubject(e.target.value)} />
                </div>

                <section className={styles.formSubmit}>
                    <label className={styles.formCheckbox}>
                        <input type="checkbox" name="politicsCheckbox" checked={acceptPrivacyPolicies} onChange={() => {
                            setAcceptPrivacyPolicies(!acceptPrivacyPolicies)
                        }} />
                        <div className={styles.formCheckboxCheckbox}>
                            <div className={styles.formCheckboxCheckmark}></div>
                        </div>
                        <span>Declaro que li e aceito as <b><u>políticas de privacidade</u></b></span>
                    </label>
                    
                    <Button text="Enviar e-mail" src={paper} hasIcon={true} disabled={!acceptPrivacyPolicies} />
                </section>
                { isDataValid === undefined ? "" :
                    <div className={`${styles.alert} ${styles.red}`}>
                        <p>Os dados informados são inválidos!</p>
                        <span className={styles.closeButton} onClick={() => {
                            setIsDataValid(undefined)
                        }}>&times;</span>
                    </div>
                }
                { wereEmailsSent === undefined ? "" : wereEmailsSent ?
                    <div className={`${styles.alert}  ${styles.green}`}>
                        <p>Emails enviados com sucesso!</p>
                        <span className={styles.closeButton} onClick={() => {
                            setWereEmailsSent(undefined)
                        }}>&times;</span>
                    </div> :
                    <div className={`${styles.alert} ${styles.red}`}>
                        <p>Ocorreu um erro inesperado!</p>
                        <span className={styles.closeButton} onClick={() => {
                            setWereEmailsSent(undefined)
                        }}>&times;</span>
                    </div>
                }
            </form>
        </section>
        {/* form End */}

        {/* Polls Start */}

        <section className={styles.pollContainer}>
            <aside className={styles.pollContainerText}>
                <h1>Incrível né?!</h1>
                <p>Participe também avaliando e comentando a enquete 
                    sobre cada projeto no site da Câmara dos Deputados 
                    através dos botões abaixo:</p>
                <div className={styles.pollButtons}>
                    <Button src="" text="PL 7005/2013" hasIcon={false} />
                    <Button src="" text="PL 3770/2013" hasIcon={false} />
                </div>
            </aside>
            <figure>
                <img src={cellPhone} alt="Phone Element" />
            </figure>
        </section>

        {/* Polls End */}

        {/* Email Counter Start */}
        <section className={styles.emailCounterContainer}>
            <h1>{sentNumber}</h1>
            <h2>E-mails enviados</h2>
        </section>
        {/* Email Counter End */}

        {/* Component End */}
        </main> 
    );
};

export default Home;
