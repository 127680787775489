// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/web/components/EmailTable.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,yBAAA;EACA,gBAAA;AACF","sourcesContent":["table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\nth, td {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\nth {\n  background-color: #f2f2f2;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
