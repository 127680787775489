class SupporterInfo {
    private _name: string
    private _email: string

    constructor(name: string, email: string) {
        this._name = name
        this._email = email
    }

    get name() {
        return this._name
    }

    get email() {
        return this._email
    }

    set name(name: string) {
        this._name = name
    }

    set email(email: string) {
        this._email = email
    }

    isValid(): boolean {
        if (!this.isNameValid()) {
            return false
        }
        if (!this.isEmailValid()) {
            return false
        }
        return true
    }

    isNameValid(): boolean {
        this._name = this._name.trim()
        return this._name.indexOf(" ") >= 0
    }

    isEmailValid(): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(this._email)
    }
}

export default SupporterInfo
