class Email {
    private _id: string
    private _senderName: string
    private _senderEmail: string
    private _subject: string
    private _body: string
    private _emailRecipientList: Array<string>
    private _createdAt: Date
    private _updatedAt: Date

    constructor(id: string, senderName: string, senderEmail: string, subject: string, body: string,
                emailRecipientList: Array<string>, createdAt: Date, updatedAt: Date) {
        this._id = id
        this._senderName = senderName
        this._senderEmail = senderEmail
        this._subject = subject
        this._body = body
        this._emailRecipientList = emailRecipientList
        this._createdAt = createdAt
        this._updatedAt = updatedAt
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get senderName(): string {
        return this._senderName;
    }

    set senderName(value: string) {
        this._senderName = value;
    }

    get senderEmail(): string {
        return this._senderEmail;
    }

    set senderEmail(value: string) {
        this._senderEmail = value;
    }

    get subject(): string {
        return this._subject;
    }

    set subject(value: string) {
        this._subject = value;
    }

    get body(): string {
        return this._body;
    }

    set body(value: string) {
        this._body = value;
    }

    get emailRecipientList(): Array<string> {
        return this._emailRecipientList;
    }

    set emailRecipientList(value: Array<string>) {
        this._emailRecipientList = value;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    set createdAt(value: Date) {
        this._createdAt = value;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    set updatedAt(value: Date) {
        this._updatedAt = value;
    }
}

export default Email
