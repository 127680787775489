import { useEffect } from 'react';
import { handlePageHighLight } from '../../contexts/functions'
import styles from './About.module.scss';
import cross from '../../assets/images/svg/cross.svg'
import trianglesOnLeft from '../../assets/images/svg/trianglesOnLeft.svg' 
import trianglesOnRight from '../../assets/images/svg/trianglesOnRight.svg' 
import trianglesMobile from '../../assets/images/svg/trianglesMobile.svg'
import downArrow from '../../assets/images/svg/downArrow.svg'
import megaphoneOverStar from '../../assets/images/png/megaphoneOverStar.png'
import Button from '../../components/Button'

const About: React.FC = () => {

  useEffect(() => {handlePageHighLight('about');});
    
  return (
    <main>
      <section className={styles.pageMainTitleContainer}>
          <figure>
              <img src={cross} alt="Header Cross" />
          </figure>
          <h1>Você quer saber o que é o projeto Sua Voz, Nossa Lei?<br />
          Vem cá que eu te conto!</h1>

          <img src={trianglesOnLeft} alt="Left triangles" className={styles.pageMainTitleTrianglesOnLeft} />
          <img src={trianglesOnRight} alt="Right triangles" className={styles.pageMainTitleTrianglesOnRight} />
          <img src={trianglesMobile} alt="Mobile triangles" className={styles.pageMainTitleTrianglesMobile} />

          <img src={downArrow} alt="downArrow" className={styles.pageMainTitleDownArrowElement} />
      </section>

      <section className={styles.aboutTextContainer}>
          <p>Você já deve ter ouvido a clássica frase <b>“Todo poder emana do povo”</b>. Então, essa frase se encontra 
            na <b>Constituição Federal</b>. Ela é a principal norma do direito do nosso país. O poder realmente tem 
            origem no povo. Mas esse poder é exercido por meio dos nossos representantes eleitos. Eles, com o 
            poder que os demos, podem criar leis sobre assuntos muito importantes para a vida das pessoas.</p>

          <h3>Mas você sabia que esse poder não é exclusivo dos políticos que nos representam?</h3>

          <p>A própria <b>Constituição Federal</b> apresenta uma possibilidade em que o povo pode exercer o poder de forma mais 
            direta criando <b>Projetos de Leis</b>. Essa possibilidade se trata da <b>Iniciativa Popular</b>.</p>

          <h3>Oque é a Iniciativa Popular? Como isso funciona?</h3>

          <p>A Iniciativa Popular é quando o povo cria um projeto de uma lei que deseja criar e coleta assinaturas 
            em apoio a essa lei desejada. Quando se alcança a quantidade necessária de assinaturas, o Projeto de Lei é enviado 
            para a Câmara dos Deputados que realiza algumas avaliações. Ele é levado à votação e, se aprovado e sancionado, se 
            torna lei.</p>

      </section>

      <section className={styles.aboutProblemContainer}>
        <aside className={styles.aboutProblemTextContainer}>
          <img src={cross} alt="Problem Cross" />
          <h1>Então, qual é o problema?</h1>
          <p>O defeito da Iniciativa Popular é que, até os dias de hoje, a coleta de assinaturas em apoio só pode ser feita 
            em papel à caneta. Isso cria uma série de dificuldades e problemas para promover um projeto desse num país 
            imenso como o nosso Brasil. Um outro problema é que a Câmara dos Deputados sempre alega que não consegue conferir 
            a validade das milhões de assinaturas.</p>
          <p>Ou seja, o Instrumento da Iniciativa Popular não acompanhou a evolução tecnológico que ocorreu nas últimas 
            décadas com a popularização da Internet e das redes sociais. Essas dificuldades seriam facilmente resolvidas 
            com a adoção de um sistema que possibilitasse a coletas de assinaturas digitais pela internet. Isso facilitaria 
            a divulgação e coleta das assinaturas por todo país.</p>
        </aside>
        <figure className={styles.aboutProblemFigureContainer}>
          <img src={megaphoneOverStar} alt="Megaphone" />
        </figure>
      </section>

      <section className={styles.aboutBillsContainer}>
        <h1>Oque são os PLs7005/2023 e 3770/2023?</h1>
        <p>Os Projetos de Lei n° 7005/2023 e3770/2023 são projetos de autoria de parlamentares e têm como objetivo 
          reconhecer legalmente a validade da assinatura digital para Iniciativa Popular. Quando esses projeto foram 
          aprovados, o instrumento da Iniciativa Popular ganhará uma força imensa em favor do povo para concretizar 
          o desejo popular na lei.
          <br /><br />
          O projeto <b>SUA VOZ, NOSSA LEI</b> temo objetivo de reunir as pessoas em favor da aprovação desses Projetos de 
          Lei para tornar viável a Iniciativa Popular e fazer valer a vontade do povo.</p>
    
        <h2>E aí? Gostou ideia? Então vem participar da modernização democrática!!! </h2>
        <p>Demonstre seu apoio com o e-mail e visitando a enquete de cada projeto nos portais da Câmara e do Senado.</p>

        <Button hasIcon={false} text='Quero Apoiar' src={""} />
      </section>

    </main>
  );
}

export default About;
