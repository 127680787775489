const emailsList = () => {
    return [
    'dep.antonialucia@camara.leg.br',
    'dep.coronelulysses@camara.leg.br',
    'dep.drfabiorueda@camara.leg.br',
    'dep.gerlendiniz@camara.leg.br',
    'dep.meireserafim@camara.leg.br',
    'dep.robertoduarte@camara.leg.br',
    'dep.socorroneri@camara.leg.br',
    'dep.zezinhobarbary@camara.leg.br',
    'dep.alfredogaspar@camara.leg.br',
    'dep.arthurlira@camara.leg.br',
    'dep.danielbarbosa@camara.leg.br',
    'dep.delegadofabiocosta@camara.leg.br',
    'dep.isnaldobulhoesjr@camara.leg.br',
    'dep.lucianoamaral@camara.leg.br',
    'dep.marxbeltrao@camara.leg.br',
    'dep.paulao@camara.leg.br',
    'dep.rafaelbrito@camara.leg.br',
    'dep.adailfilho@camara.leg.br',
    'dep.amommandel@camara.leg.br',
    'dep.atilalins@camara.leg.br',
    'dep.capitaoalbertoneto@camara.leg.br',
    'dep.pauderneyavelino@camara.leg.br',
    'dep.saullovianna@camara.leg.br',
    'dep.sidneyleite@camara.leg.br',
    'dep.silascamara@camara.leg.br',
    'dep.acaciofavacho@camara.leg.br',
    'dep.augustopuppio@camara.leg.br',
    'dep.dorinaldomalafaia@camara.leg.br',
    'dep.josenildo@camara.leg.br',
    'dep.professoragoreth@camara.leg.br',
    'dep.silviawaiapi@camara.leg.br',
    'dep.sonizebarbosa@camara.leg.br',
    'dep.viniciusgurgel@camara.leg.br',
    'dep.adolfoviana@camara.leg.br',
    'dep.alexsantana@camara.leg.br',
    'dep.aliceportugal@camara.leg.br',
    'dep.antoniobrito@camara.leg.br',
    'dep.arthuroliveiramaia@camara.leg.br',
    'dep.bacelar@camara.leg.br',
    'dep.capitaoalden@camara.leg.br',
    'dep.charlesfernandes@camara.leg.br',
    'dep.claudiocajado@camara.leg.br',
    'dep.dalbarreto@camara.leg.br',
    'dep.danielalmeida@camara.leg.br',
    'dep.diegocoronel@camara.leg.br',
    'dep.elmarnascimento@camara.leg.br',
    'dep.felixmendoncajunior@camara.leg.br',
    'dep.gabrielnunes@camara.leg.br',
    'dep.ivoneidecaetano@camara.leg.br',
    'dep.joaocarlosbacelar@camara.leg.br',
    'dep.joaoleao@camara.leg.br',
    'dep.jorgesolla@camara.leg.br',
    'dep.joserocha@camara.leg.br',
    'dep.joseildoramos@camara.leg.br',
    'dep.josiasgomes@camara.leg.br',
    'dep.leoprates@camara.leg.br',
    'dep.leurlomantojunior@camara.leg.br',
    'dep.lidicedamata@camara.leg.br',
    'dep.marciomarinho@camara.leg.br',
    'dep.marionegromontejr@camara.leg.br',
    'dep.netocarletto@camara.leg.br',
    'dep.ottoalencarfilho@camara.leg.br',
    'dep.pastorsargentoisidorio@camara.leg.br',
    'dep.pauloazi@camara.leg.br',
    'dep.paulomagalhaes@camara.leg.br',
    'dep.raimundocosta@camara.leg.br',
    'dep.ricardomaia@camara.leg.br',
    'dep.robertaroma@camara.leg.br',
    'dep.rogeriasantos@camara.leg.br',
    'dep.valmirassuncao@camara.leg.br',
    'dep.waldenorpereira@camara.leg.br',
    'dep.zeneto@camara.leg.br',
    'dep.ajalbuquerque@camara.leg.br',
    'dep.andrefernandes@camara.leg.br',
    'dep.andrefigueiredo@camara.leg.br',
    'dep.daniloforte@camara.leg.br',
    'dep.dayanybittencourt@camara.leg.br',
    'dep.domingosneto@camara.leg.br',
    'dep.dr.jaziel@camara.leg.br',
    'dep.eduardobismarck@camara.leg.br',
    'dep.euniciooliveira@camara.leg.br',
    'dep.fernandapessoa@camara.leg.br',
    'dep.idilvanalencar@camara.leg.br',
    'dep.joseairtonfelixcirilo@camara.leg.br',
    'dep.joseguimaraes@camara.leg.br',
    'dep.juniormano@camara.leg.br',
    'dep.leonidascristino@camara.leg.br',
    'dep.luizgastao@camara.leg.br',
    'dep.luiziannelins@camara.leg.br',
    'dep.matheusnoronha@camara.leg.br',
    'dep.maurobenevidesfilho@camara.leg.br',
    'dep.mosesrodrigues@camara.leg.br',
    'dep.naumiamorim@camara.leg.br',
    'dep.yurydoparedao@camara.leg.br',
    'dep.albertofraga@camara.leg.br',
    'dep.biakicis@camara.leg.br',
    'dep.erikakokay@camara.leg.br',
    'dep.fredlinhares@camara.leg.br',
    'dep.gilvanmaximo@camara.leg.br',
    'dep.juliocesarribeiro@camara.leg.br',
    'dep.prof.reginaldoveras@camara.leg.br',
    'dep.rafaelprudente@camara.leg.br',
    'dep.amaroneto@camara.leg.br',
    'dep.davitoria@camara.leg.br',
    'dep.dr.victorlinhalis@camara.leg.br',
    'dep.evairvieirademelo@camara.leg.br',
    'dep.gilsondaniel@camara.leg.br',
    'dep.gilvandafederal@camara.leg.br',
    'dep.heldersalomao@camara.leg.br',
    'dep.jackrocha@camara.leg.br',
    'dep.messiasdonato@camara.leg.br',
    'dep.paulofolletto@camara.leg.br',
    'dep.adrianodobaldy@camara.leg.br',
    'dep.celiosilveira@camara.leg.br',
    'dep.danielagrobom@camara.leg.br',
    'dep.delegadaadrianaaccorsi@camara.leg.br',
    'dep.dr.zachariascalil@camara.leg.br',
    'dep.flaviamorais@camara.leg.br',
    'dep.glaustindafokus@camara.leg.br',
    'dep.gustavogayer@camara.leg.br',
    'dep.hildodocandango@camara.leg.br',
    'dep.ismaelalexandrino@camara.leg.br',
    'dep.josenelto@camara.leg.br',
    'dep.ledaborges@camara.leg.br',
    'dep.magdamofatto@camara.leg.br',
    'dep.marussaboldrin@camara.leg.br',
    'dep.professoralcides@camara.leg.br',
    'dep.rubensotoni@camara.leg.br',
    'dep.silvyealves@camara.leg.br',
    'dep.amandagentil@camara.leg.br',
    'dep.cleberverde@camara.leg.br',
    'dep.dr.allangarces@camara.leg.br',
    'dep.dr.benjamim@camara.leg.br',
    'dep.duartejr@camara.leg.br',
    'dep.fabiomacedo@camara.leg.br',
    'dep.henriquejunior@camara.leg.br',
    'dep.josivaldojp@camara.leg.br',
    'dep.lucianogalego@camara.leg.br',
    'dep.marciohonaiser@camara.leg.br',
    'dep.marciojerry@camara.leg.br',
    'dep.marianacarvalhoma@camara.leg.br',
    'dep.paulomarinhojr@camara.leg.br',
    'dep.pedrolucasfernandes@camara.leg.br',
    'dep.roseanasarney@camara.leg.br',
    'dep.rubenspereirajunior@camara.leg.br',
    'dep.silvioantonio@camara.leg.br',
    'dep.wolmeraraujo@camara.leg.br',
    'dep.aecioneves@camara.leg.br',
    'dep.anapaulaleao@camara.leg.br',
    'dep.anapimentel@camara.leg.br',
    'dep.andrejanones@camara.leg.br',
    'dep.brunofarias@camara.leg.br',
    'dep.celiaxakriaba@camara.leg.br',
    'dep.dandara@camara.leg.br',
    'dep.delegadaione@camara.leg.br',
    'dep.delegadomarcelofreitas@camara.leg.br',
    'dep.diegoandrade@camara.leg.br',
    'dep.dimasfabiano@camara.leg.br',
    'dep.domingossavio@camara.leg.br',
    'dep.dr.frederico@camara.leg.br',
    'dep.duartegoncalvesjr@camara.leg.br',
    'dep.dudasalabert@camara.leg.br',
    'dep.emidinhomadeira@camara.leg.br',
    'dep.erosbiondini@camara.leg.br',
    'dep.felipesaliba@camara.leg.br',
    'dep.gilbertoabramo@camara.leg.br',
    'dep.greyceelias@camara.leg.br',
    'dep.herciliocoelhodiniz@camara.leg.br',
    'dep.igortimo@camara.leg.br',
    'dep.junioamaral@camara.leg.br',
    'dep.lafayettedeandrada@camara.leg.br',
    'dep.leonardomonteiro@camara.leg.br',
    'dep.lincolnportela@camara.leg.br',
    'dep.luistibe@camara.leg.br',
    'dep.luizfernandofaria@camara.leg.br',
    'dep.marceloalvaroantonio@camara.leg.br',
    'dep.marioheringer@camara.leg.br',
    'dep.mauriciodovolei@camara.leg.br',
    'dep.miguelangelo@camara.leg.br',
    'dep.misaelvarella@camara.leg.br',
    'dep.nelyaquino@camara.leg.br',
    'dep.nikolasferreira@camara.leg.br',
    'dep.odaircunha@camara.leg.br',
    'dep.padrejoao@camara.leg.br',
    'dep.patrusananias@camara.leg.br',
    'dep.pauloabiackel@camara.leg.br',
    'dep.pauloguedes@camara.leg.br',
    'dep.pedroaihara@camara.leg.br',
    'dep.pinheirinho@camara.leg.br',
    'dep.rafaelsimoes@camara.leg.br',
    'dep.reginaldolopes@camara.leg.br',
    'dep.rodrigodecastro@camara.leg.br',
    'dep.rogeriocorreia@camara.leg.br',
    'dep.rosangelareis@camara.leg.br',
    'dep.samuelviana@camara.leg.br',
    'dep.stefanoaguiar@camara.leg.br',
    'dep.ulissesguimaraes@camara.leg.br',
    'dep.welitonprado@camara.leg.br',
    'dep.zesilva@camara.leg.br',
    'dep.zevitor@camara.leg.br',
    'dep.betopereira@camara.leg.br',
    'dep.camilajara@camara.leg.br',
    'dep.dagobertonogueira@camara.leg.br',
    'dep.dr.luizovando@camara.leg.br',
    'dep.geraldoresende@camara.leg.br',
    'dep.marcospollon@camara.leg.br',
    'dep.rodolfonogueira@camara.leg.br',
    'dep.vanderloubet@camara.leg.br',
    'dep.abiliobrunini@camara.leg.br',
    'dep.amaliabarros@camara.leg.br',
    'dep.coronelassis@camara.leg.br',
    'dep.coronelfernanda@camara.leg.br',
    'dep.emanuelpinheironeto@camara.leg.br',
    'dep.giselasimona@camara.leg.br',
    'dep.josemedeiros@camara.leg.br',
    'dep.juarezcosta@camara.leg.br',
    'dep.airtonfaleiro@camara.leg.br',
    'dep.andreiasiqueira@camara.leg.br',
    'dep.antoniodoido@camara.leg.br',
    'dep.delegadocaveira@camara.leg.br',
    'dep.delegadoedermauro@camara.leg.br',
    'dep.dilvandafaro@camara.leg.br',
    'dep.dra.alessandrahaber@camara.leg.br',
    'dep.elcionebarbalho@camara.leg.br',
    'dep.helioleite@camara.leg.br',
    'dep.hendersonpinto@camara.leg.br',
    'dep.joaquimpassarinho@camara.leg.br',
    'dep.josepriante@camara.leg.br',
    'dep.juniorferrari@camara.leg.br',
    'dep.kenistonbraga@camara.leg.br',
    'dep.olivalmarques@camara.leg.br',
    'dep.raimundosantos@camara.leg.br',
    'dep.renilcenicodemos@camara.leg.br',
    'dep.aguinaldoribeiro@camara.leg.br',
    'dep.cabogilbertosilva@camara.leg.br',
    'dep.damiaofeliciano@camara.leg.br',
    'dep.gervasiomaia@camara.leg.br',
    'dep.hugomotta@camara.leg.br',
    'dep.luizcouto@camara.leg.br',
    'dep.mersinholucena@camara.leg.br',
    'dep.murilogaldino@camara.leg.br',
    'dep.ranierypaulino@camara.leg.br',
    'dep.romerorodrigues@camara.leg.br',
    'dep.ruycarneiro@camara.leg.br',
    'dep.wellingtonroberto@camara.leg.br',
    'dep.andreferreira@camara.leg.br',
    'dep.augustocoutinho@camara.leg.br',
    'dep.carlosveras@camara.leg.br',
    'dep.clarissatercio@camara.leg.br',
    'dep.clodoaldomagalhaes@camara.leg.br',
    'dep.coronelmeira@camara.leg.br',
    'dep.eduardodafonte@camara.leg.br',
    'dep.eribertomedeiros@camara.leg.br',
    'dep.felipecarreras@camara.leg.br',
    'dep.fernandocoelhofilho@camara.leg.br',
    'dep.fernandomonteiro@camara.leg.br',
    'dep.fernandorodolfo@camara.leg.br',
    'dep.guilhermeuchoa@camara.leg.br',
    'dep.izaarruda@camara.leg.br',
    'dep.lucasramos@camara.leg.br',
    'dep.lucianobivar@camara.leg.br',
    'dep.luladafonte@camara.leg.br',
    'dep.mariaarraes@camara.leg.br',
    'dep.mendoncafilho@camara.leg.br',
    'dep.ossesiosilva@camara.leg.br',
    'dep.pastoreurico@camara.leg.br',
    'dep.pedrocampos@camara.leg.br',
    'dep.renildocalheiros@camara.leg.br',
    'dep.tuliogadelha@camara.leg.br',
    'dep.waldemaroliveira@camara.leg.br',
    'dep.atilamlira@camara.leg.br',
    'dep.castroneto@camara.leg.br',
    'dep.dr.francisco@camara.leg.br',
    'dep.flavionogueira@camara.leg.br',
    'dep.florentinoneto@camara.leg.br',
    'dep.jadyelalencar@camara.leg.br',
    'dep.julioarcoverde@camara.leg.br',
    'dep.juliocesar@camara.leg.br',
    'dep.marcosaureliosampaio@camara.leg.br',
    'dep.merlongsolano@camara.leg.br',
    'dep.alielmachado@camara.leg.br',
    'dep.betoricha@camara.leg.br',
    'dep.caroldartora@camara.leg.br',
    'dep.delegadomatheuslaiola@camara.leg.br',
    'dep.diegogarcia@camara.leg.br',
    'dep.dilceusperafico@camara.leg.br',
    'dep.felipefrancischini@camara.leg.br',
    'dep.filipebarros@camara.leg.br',
    'dep.geraldomendes@camara.leg.br',
    'dep.giacobo@camara.leg.br',
    'dep.gleisihoffmann@camara.leg.br',
    'dep.lucianoalves@camara.leg.br',
    'dep.lucianoducci@camara.leg.br',
    'dep.luisacanziani@camara.leg.br',
    'dep.luizcarloshauly@camara.leg.br',
    'dep.luiznishimori@camara.leg.br',
    'dep.marcobrasil@camara.leg.br',
    'dep.padovani@camara.leg.br',
    'dep.paulolitro@camara.leg.br',
    'dep.pedrolupion@camara.leg.br',
    'dep.reinholdstephanes@camara.leg.br',
    'dep.rodrigoestacho@camara.leg.br',
    'dep.sargentofahur@camara.leg.br',
    'dep.sergiosouza@camara.leg.br',
    'dep.tadeuveneri@camara.leg.br',
    'dep.tiaomedeiros@camara.leg.br',
    'dep.toninhowandscheer@camara.leg.br',
    'dep.vermelho@camara.leg.br',
    'dep.welter@camara.leg.br',
    'dep.zecadirceu@camara.leg.br',
    'dep.altineucortes@camara.leg.br',
    'dep.aureoribeiro@camara.leg.br',
    'dep.bandeirademello@camara.leg.br',
    'dep.bebeto@camara.leg.br',
    'dep.beneditadasilva@camara.leg.br',
    'dep.caiovianna@camara.leg.br',
    'dep.carlosjordy@camara.leg.br',
    'dep.chicoalencar@camara.leg.br',
    'dep.chiquinhobrazao@camara.leg.br',
    'dep.christonietto@camara.leg.br',
    'dep.danicunha@camara.leg.br',
    'dep.danieladowaguinho@camara.leg.br',
    'dep.delegadoramagem@camara.leg.br',
    'dep.dimasgadelha@camara.leg.br',
    'dep.doutorluizinho@camara.leg.br',
    'dep.generalpazuello@camara.leg.br',
    'dep.glauberbraga@camara.leg.br',
    'dep.gutembergreis@camara.leg.br',
    'dep.heliolopes@camara.leg.br',
    'dep.hugoleal@camara.leg.br',
    'dep.jandirafeghali@camara.leg.br',
    'dep.jorgebraz@camara.leg.br',
    'dep.juliolopes@camara.leg.br',
    'dep.juninhodopneu@camara.leg.br',
    'dep.lauracarneiro@camara.leg.br',
    'dep.lindberghfarias@camara.leg.br',
    'dep.lucianovieira@camara.leg.br',
    'dep.luiscarlosgomes@camara.leg.br',
    'dep.luizlima@camara.leg.br',
    'dep.marcelocrivella@camara.leg.br',
    'dep.marceloqueiroz@camara.leg.br',
    'dep.marcossoares@camara.leg.br',
    'dep.marcostavares@camara.leg.br',
    'dep.maxlemos@camara.leg.br',
    'dep.murillogouvea@camara.leg.br',
    'dep.otoni   depaula@camara.leg.br',
    'dep.pastorhenriquevieira@camara.leg.br',
    'dep.pedropaulo@camara.leg.br',
    'dep.reimont@camara.leg.br',
    'dep.robertomonteiropai@camara.leg.br',
    'dep.sargentoportugal@camara.leg.br',
    'dep.sorayasantos@camara.leg.br',
    'dep.sostenescavalcante@camara.leg.br',
    'dep.taliriapetrone@camara.leg.br',
    'dep.tarcisiomotta@camara.leg.br',
    'dep.washingtonquaqua@camara.leg.br',
    'dep.benesleocadio@camara.leg.br',
    'dep.fernandomineiro@camara.leg.br',
    'dep.generalgirao@camara.leg.br',
    'dep.joaomaia@camara.leg.br',
    'dep.nataliabonavides@camara.leg.br',
    'dep.paulinhofreire@camara.leg.br',
    'dep.robinsonfaria@camara.leg.br',
    'dep.sargentogoncalves@camara.leg.br',
    'dep.coronelchrisostomo@camara.leg.br',
    'dep.cristianelopes@camara.leg.br',
    'dep.dr.fernandomaximo@camara.leg.br',
    'dep.lebrao@camara.leg.br',
    'dep.luciomosquini@camara.leg.br',
    'dep.mauriciocarvalho@camara.leg.br',
    'dep.silviacristina@camara.leg.br',
    'dep.thiagoflores@camara.leg.br',
    'dep.albuquerque@camara.leg.br',
    'dep.defensorsteliodener@camara.leg.br',
    'dep.dudaramos@camara.leg.br',
    'dep.gabrielmota@camara.leg.br',
    'dep.helenalima@camara.leg.br',
    'dep.nicoletti@camara.leg.br',
    'dep.pastordiniz@camara.leg.br',
    'dep.zeharoldocathedral@camara.leg.br',
    'dep.afonsohamm@camara.leg.br',
    'dep.afonsomotta@camara.leg.br',
    'dep.alceumoreira@camara.leg.br',
    'dep.alexandrelindenmeyer@camara.leg.br',
    'dep.anyortiz@camara.leg.br',
    'dep.bibonunes@camara.leg.br',
    'dep.bohngass@camara.leg.br',
    'dep.covattifilho@camara.leg.br',
    'dep.daianasantos@camara.leg.br',
    'dep.danieltrzeciak@camara.leg.br',
    'dep.denisepessoa@camara.leg.br',
    'dep.fernandamelchionna@camara.leg.br',
    'dep.francianebayer@camara.leg.br',
    'dep.giovanicherini@camara.leg.br',
    'dep.heitorschuch@camara.leg.br',
    'dep.lucasredecker@camara.leg.br',
    'dep.lucianoazevedo@camara.leg.br',
    'dep.luizcarlosbusato@camara.leg.br',
    'dep.marcelvanhattem@camara.leg.br',
    'dep.marcelomoraes@camara.leg.br',
    'dep.marciobiolchi@camara.leg.br',
    'dep.marcon@camara.leg.br',
    'dep.mariadorosario@camara.leg.br',
    'dep.mauriciomarcon@camara.leg.br',
    'dep.osmarterra@camara.leg.br',
    'dep.pedrowestphalen@camara.leg.br',
    'dep.pompeodemattos@camara.leg.br',
    'dep.reginetebispo@camara.leg.br',
    'dep.ronaldonogueira@camara.leg.br',
    'dep.sanderson@camara.leg.br',
    'dep.zucco@camara.leg.br',
    'dep.anapaulalima@camara.leg.br',
    'dep.carloschiodini@camara.leg.br',
    'dep.carolinedetoni@camara.leg.br',
    'dep.cobalchini@camara.leg.br',
    'dep.danielfreitas@camara.leg.br',
    'dep.danielareinehr@camara.leg.br',
    'dep.darcidematos@camara.leg.br',
    'dep.fabioschiochet@camara.leg.br',
    'dep.geovaniadesa@camara.leg.br',
    'dep.gilsonmarques@camara.leg.br',
    'dep.ismael@camara.leg.br',
    'dep.jorgegoetten@camara.leg.br',
    'dep.juliazanatta@camara.leg.br',
    'dep.pedrouczai@camara.leg.br',
    'dep.pezenti@camara.leg.br',
    'dep.zetrovao@camara.leg.br',
    'dep.delegadakatarina@camara.leg.br',
    'dep.gustinhoribeiro@camara.leg.br',
    'dep.icarodevalmir@camara.leg.br',
    'dep.joaodaniel@camara.leg.br',
    'dep.nitinho@camara.leg.br',
    'dep.rodrigovaladares@camara.leg.br',
    'dep.thiagodejoaldo@camara.leg.br',
    'dep.yandramoura@camara.leg.br',
    'dep.adrianaventura@camara.leg.br',
    'dep.albertomourao@camara.leg.br',
    'dep.alencarsantana@camara.leg.br',
    'dep.alexmanente@camara.leg.br',
    'dep.alexandreleite@camara.leg.br',
    'dep.alfredinho@camara.leg.br',
    'dep.antoniocarlosrodrigues@camara.leg.br',
    'dep.arlindochinaglia@camara.leg.br',
    'dep.arnaldojardim@camara.leg.br',
    'dep.baleiarossi@camara.leg.br',
    'dep.brunoganem@camara.leg.br',
    'dep.capitaoaugusto@camara.leg.br',
    'dep.carlazambelli@camara.leg.br',
    'dep.carlossampaio@camara.leg.br',
    'dep.carloszarattini@camara.leg.br',
    'dep.celsorussomanno@camara.leg.br',
    'dep.cezinhademadureira@camara.leg.br',
    'dep.coroneltelhada@camara.leg.br',
    'dep.davidsoares@camara.leg.br',
    'dep.delegadodacunha@camara.leg.br',
    'dep.delegadopalumbo@camara.leg.br',
    'dep.delegadopaulobilynskyj@camara.leg.br',
    'dep.douglasviegas@camara.leg.br',
    'dep.eduardobolsonaro@camara.leg.br',
    'dep.elysantos@camara.leg.br',
    'dep.erikahilton@camara.leg.br',
    'dep.fabioteruel@camara.leg.br',
    'dep.faustopinato@camara.leg.br',
    'dep.gilbertonascimento@camara.leg.br',
    'dep.guilhermeboulos@camara.leg.br',
    'dep.guilhermederrite@camara.leg.br',
    'dep.ivanvalente@camara.leg.br',
    'dep.jeffersoncampos@camara.leg.br',
    'dep.jilmartatto@camara.leg.br',
    'dep.jonasdonizette@camara.leg.br',
    'dep.julianacardoso@camara.leg.br',
    'dep.kikoceleguim@camara.leg.br',
    'dep.kimkataguiri@camara.leg.br',
    'dep.luizcarlosmotta@camara.leg.br',
    'dep.luizphilippedeorleansebraganca@camara.leg.br',
    'dep.luizaerundina@camara.leg.br',
    'dep.marangoni@camara.leg.br',
    'dep.marcioalvino@camara.leg.br',
    'dep.marcospereira@camara.leg.br',
    'dep.mariarosas@camara.leg.br',
    'dep.mariofrias@camara.leg.br',
    'dep.mauricioneves@camara.leg.br',
    'dep.miguellombardi@camara.leg.br',
    'dep.niltotatto@camara.leg.br',
    'dep.orlandosilva@camara.leg.br',
    'dep.paulinhodaforca@camara.leg.br',
    'dep.pauloalexandrebarbosa@camara.leg.br',
    'dep.paulofreirecosta@camara.leg.br',
    'dep.pr.marcofeliciano@camara.leg.br',
    'dep.professoralucienecavalcante@camara.leg.br',
    'dep.renataabreu@camara.leg.br',
    'dep.ricardosalles@camara.leg.br',
    'dep.ricardosilva@camara.leg.br',
    'dep.rodrigogambale@camara.leg.br',
    'dep.rosanavalle@camara.leg.br',
    'dep.rosangelamoro@camara.leg.br',
    'dep.ruifalcao@camara.leg.br',
    'dep.samiabomfim@camara.leg.br',
    'dep.saulopedroso@camara.leg.br',
    'dep.simonemarquetto@camara.leg.br',
    'dep.tabataamaral@camara.leg.br',
    'dep.tiririca@camara.leg.br',
    'dep.vicentinho@camara.leg.br',
    'dep.viniciuscarvalho@camara.leg.br',
    'dep.vitorlippi@camara.leg.br',
    'dep.alexandreguimaraes@camara.leg.br',
    'dep.antonioandrade@camara.leg.br',
    'dep.carloshenriquegaguim@camara.leg.br',
    'dep.eliborges@camara.leg.br',
    'dep.filipemartins@camara.leg.br',
    'dep.lazarobotelho@camara.leg.br',
    'dep.ricardoayres@camara.leg.br',
    'dep.vicentinhojunior@camara.leg.br',
    ]
}

export default emailsList;