import styles from './Header.module.scss';
import logo from "../assets/images/svg/logo.svg";
import whiteLogo from "../assets/images/svg/whiteLogo.svg";

const Header = () => {

    const handleOpenNavBar: any = () => {
        const mobileNavBar:any = document.getElementById('mobileNavBar');
        mobileNavBar.style.display = 'flex';
    }

    const handleCloseNavBar: any = () => {
        const mobileNavBar:any = document.getElementById('mobileNavBar');
        mobileNavBar.style.display = 'none';
    }

  return (
    <header className={styles.mainHeader}>
            <section className={styles.mainHeaderContainer}>
                <img src={logo} alt="Sua voz, Nossa lei Logo" className={styles.mainHeaderContainerLogo} />
                <nav className={styles.mainHeaderContainerNav}>
                    <ul>
                        <li>
                            <a href="/home" id='home'>Início</a>
                        </li>
                        <li>
                            <a href="/about" id='about'>Sobre</a>
                        </li>
                        <li>
                            <a href="/contact" id='contact'>Contato</a>
                        </li>
                    </ul>
                </nav>
            </section>

            <section className={styles.mainMobileHeaderContainer}>
                <img src={whiteLogo} alt="Sua voz, Nossa lei Logo Mobile" className={styles.mainHeaderContainerLogo} />
                <label className={styles.mainMobileHeaderContainerButton} onClick={handleOpenNavBar}>
                    <p></p>
                    <p></p>
                    <p></p>
                </label>
            </section>

            <nav className={styles.mobileNavBar} id='mobileNavBar'>
                <ul>
                    <li>
                        <a href="/home" id='homeMobile'>Início</a>
                    </li>
                    <li>
                        <a href="/about" id='aboutMobile'>Sobre</a>
                    </li>
                    <li>
                        <a href="/contact" id='contactMobile'>Contato</a>
                    </li>
                </ul>

                <label className={styles.mainMobileHeaderContainerCloseButton} onClick={handleCloseNavBar}>
                    <p></p>
                    <p></p>
                </label>
            </nav>


    </header>
  );
}

export default Header;