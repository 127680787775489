import './App.scss';
import Router from './app/web/routes/Router'
import Header from './app/web/components/Header';
import Footer from './app/web/components/Footer';

function App() {

  return (
    <div className="App">
      <Header />
      <Router />
      <Footer />
    </div>
  );
}

export default App;
