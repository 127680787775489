import EmailPort from "../../../core/interfaces/primary/EmailPort";
import EmailRepository from "../../../infra/api/EmailRepository";
import EmailServices from "../../../core/services/EmailServices";

function GetEmailServices(): EmailPort {
    let repository = new EmailRepository()
    return new EmailServices(repository)
}

export default GetEmailServices
