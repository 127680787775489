import styles from './Footer.module.scss';
import logo from "../assets/images/svg/logo.svg";

const Footer = () => {
    return (
        <footer className={styles.mainFooter}>
            <div className={styles.footerElements}>
            <img src={logo} alt="Sua voz, Nossa lei Logo" className={styles.mainHeaderContainerLogoMobile} />
                <p>Agradecemos sinceramente por sua dedicação à construção de um futuro mais democrático para todos.<br />
                </p>
                <p>Atenciosamente,</p>
                <p>Izael Oliveira / iniciativapopulardigital@gmail.com</p>
                <img src={logo} alt="Sua voz, Nossa lei Logo" className={styles.mainHeaderContainerLogo} />
            </div>
        </footer>
    )
}

export default Footer;