import React, { useEffect } from 'react'
import EmailTable from "../../components/EmailTable"
import Email from "../../../../core/domain/models/Email"
import styles from './Email.module.scss'
import GetEmailServices from "../../dicontainer/dicontainer";
import EmailFilters from "../../../../core/services/filters/EmailFilters";

const EmailPage: React.FC = () => {
    const services = GetEmailServices()
    const [emails, setEmails] = React.useState(Array<Email>());
    const [name, setName] = React.useState<string>()
    const [email, setEmail] = React.useState<string>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await services.list()
                setEmails(response)
            } catch (error) {
                console.error(error)
            }
        };
        fetchData()
    }, [])

    async function onSubmit() {
        const filters = new EmailFilters(name, email)
        const emailList = await services.list(filters)
        setEmails(emailList)
    }

    return (
        <main className={styles.container} style={{position: 'relative'}}>
            <div>
                <h3>Filtros</h3>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit()
                }}>
                    <label>Nome: </label>
                    <input
                        type="text"
                        name="name"
                        placeholder='Nome'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setName(e.target.value)
                        }} />
                    <br/><br/>
                    <label>E-mail: </label>
                    <input
                        type="text"
                        name="email"
                        placeholder='E-mail'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value)
                        }} />
                    <div>
                        <br/>
                        <input type="submit" value="Pesquisar"/>
                    </div>
                </form>
            </div>
            <div>
                <h1>Lista de emails</h1>
                <EmailTable emails={emails ? emails : []} />
            </div>
        </main>
    )
}

export default EmailPage

