import EmailPort from "../interfaces/primary/EmailPort"
import SupporterInfo from "../domain/models/SupporterInfo"
import Email from "../domain/models/Email"
import EmailFilters from "./filters/EmailFilters";

class EmailServices extends EmailPort {
    private _repository: EmailPort

    constructor(repository: EmailPort) {
        super();
        this._repository = repository
    }

    send(supporterInfo: SupporterInfo): Promise<void> {
        return this._repository.send(supporterInfo)
    }

    getSentNumber(): Promise<number> {
        return this._repository.getSentNumber()
    }

    list(filters?: EmailFilters): Promise<Array<Email>> {
        return this._repository.list(filters)
    }

    deleteBySenderEmail(senderEmail: string): Promise<void> {
        return this._repository.deleteBySenderEmail(senderEmail)
    }
}

export default EmailServices
